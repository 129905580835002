import { makeRequest } from "../utils/http/http"
import { configuration } from "../utils/oidc/configurations"
import Http, { httpMethods } from "../utils/http/apiHelper"
import storage from "../utils/http/storage"

var backApiUri = configuration.back_api_uri

export const createTeam = (name: string) => {
  return makeRequest(`${backApiUri}/team`, "POST", {
    Name: name,
  })
}

export const joinTeam = (teamId: string) => {
  return makeRequest(`${backApiUri}/team/${teamId}/join`, "POST", {})
}

export const leaveTeam = (teamId: string) => {
  return makeRequest(`${backApiUri}/team/${teamId}/leave`, "POST", {})
}

export const graphql = (query: object) => {
  return makeRequest(`${backApiUri}/graphql`, "POST", query)
}

export const joinTeamByCode = (code: string) => {
  return Http.setMethod(httpMethods.post)
    .setEndpoint(`${backApiUri}/team/join-by-code?code=${code}`)
    .setToken(storage.getToken())
    .request()
}

export const getTeamByCode = (code: string) => {
  var query = `{
    teams(
      where: {code : { eq: "${code}"}}) {
      id,
      name
      users {
        id,
        fullName
      },
      leagues {
        id,
        name
      }
    }
  }`
  return Http.setMethod(httpMethods.post)
    .setEndpoint(`${backApiUri}/graphql`)
    .setPayload({ query })
    .setToken(storage.getToken())
    .request()
}

export const getTeamSummarized = (teamId: string) => {
  return Http.setMethod(httpMethods.get)
    .setEndpoint(`${backApiUri}/team/${teamId}/summarized`)
    .setToken(storage.getToken())
    .request<any>()
}
