interface config {
  origin: string
  client_id: string
  redirect_uri: string
  response_type: string
  automaticSilentRenew: string
  scope: string
  authority: string
  silent_redirect_uri: string
  refresh_time_before_tokens_expiration_in_second: number
  loadUserInfo: string
  monitorSession: string
  back_api_uri: string
}

export const configuration: config = {
  origin: import.meta.env.VITE_ORIGIN as string,
  client_id: import.meta.env.VITE_CLIENT_ID as string,
  redirect_uri: import.meta.env.VITE_REDIRECT_URI as string,
  response_type: import.meta.env.VITE_RESPONSE_TYPE as string,
  automaticSilentRenew: import.meta.env.VITE_AUTOMATIC_SILENT_RENEW as string,
  scope: import.meta.env.VITE_SCOPE as string,
  authority: import.meta.env.VITE_AUTHORITY as string,
  silent_redirect_uri: import.meta.env.VITE_SILENT_REDIRECT_URI as string,
  refresh_time_before_tokens_expiration_in_second: import.meta.env
    .VITE_REFRESH_TIME_BEFORE_TOKENS_EXPIRATION_IN_SECOND as unknown as number,
  loadUserInfo: import.meta.env.VITE_LOAD_USER_INFO as string,
  monitorSession: import.meta.env.VITE_MONITOR_SESSION as string,
  back_api_uri: import.meta.env.VITE_BACK_API_URL as string,
}
