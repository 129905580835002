import axios from "axios"

export const makeRequest = (endpoint: string, method: string, payload: {}) => {
  var token = { tokens: { accessToken: {} } }
  try {
    token = JSON.parse(sessionStorage.getItem(`oidc.default`)!)
  } catch (error) {
    // toast.error(<Warning text={`Try without incognito mode. ${error}`} />);
  }

  const options = {
    url: `${endpoint}`,
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      ...(token && { Authorization: `Bearer ${token.tokens.accessToken}` }),
    },
    data: payload ?? null,
  }

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        return Promise.resolve(error.response)
      } else {
        return Promise.reject(error)
      }
    },
  )

  return axios(options)
    .then((response) => {
      if (
        response.status === 200 ||
        response.status === 204 ||
        response.status === 201
      ) {
        return response.data
      }
    })
    .catch((error) => {
      throw error.statusText
    })
}
