import { useEffect, useState } from "react"
import {
  AppBar,
  Button,
  Container,
  Grid,
  Toolbar,
  Popover,
  Stack,
  IconButton,
  useTheme,
  Box,
} from "@mui/material"
import { Link } from "react-router-dom"
import CalculateIcon from "@mui/icons-material/Calculate"
import BarChartIcon from "@mui/icons-material/BarChart"
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary"
import MenuIcon from "@mui/icons-material/Menu"
import HomeIcon from "@mui/icons-material/Home"
import {
  OidcSecure,
  OidcUserStatus,
  useOidc,
  useOidcUser,
} from "@axa-fr/react-oidc"
import AddIcon from "@mui/icons-material/Add"
import Brightness4Icon from "@mui/icons-material/Brightness4"
import Brightness7Icon from "@mui/icons-material/Brightness7"
import Groups2Icon from "@mui/icons-material/Groups2"
import useMediaQuery from "@mui/material/useMediaQuery"
import DashboardIcon from "@mui/icons-material/Dashboard"
import { Tooltip } from "@mui/material"

import "./style.css"
import { LanguagePopover } from "./languagePopover"
import { LoginSection } from "./loginSection"
import { AddActivity } from "./addActivity"
import { AddActivityDialog } from "../activity/addActivityDialog"
import { graphql } from "../../api/teamApi"
import { useDispatch } from "react-redux"
import {
  setCurrentLeague,
  setLeagueId,
  setLeagues,
} from "../../features/league/leagueSlice"
import { setTeamId, setTeams } from "../../features/team/teamSlice"
import { setUserId } from "../../features/user/userSlice"

export const Header = (props: any) => {
  const theme = useTheme()
  const [data, setData] = useState<any>()
  const { oidcUser, oidcUserLoadingState } = useOidcUser()
  const { login, logout, renewTokens, isAuthenticated } = useOidc()

  const [openAddActivity, setOpenAddActivity] = useState(false)
  const dispatch = useDispatch()
  const handleOpenAddActivity = () => {
    setOpenAddActivity(true)
  }

  const handleCloseAddActivity = () => {
    setOpenAddActivity(false)
  }

  const fetchData = () => {
    graphql({
      query: `{
        users(
          where: {id : { eq: "${oidcUser.sub}"}}) {
          id,
          email,
          teams {
            id,
            name,
            roles {
              name
            }
            leagues {
              id
              name
              startDate
              endDate
              roles {
                name
              }
            }
          }
        }
      }`,
    })
      .then((data: any) => {
        setData(data.data.users[0])

        var leagueId = data.data.users[0]?.teams[0]?.leagues[0]?.id
        var teamId = data.data.users[0].teams[0].id

        dispatch(setLeagues(data.data.users[0]?.teams[0].leagues))
        dispatch(setCurrentLeague(data.data.users[0]?.teams[0].leagues[0]))
        dispatch(setLeagueId(leagueId))
        dispatch(setTeamId(teamId))

        graphql({
          query: `{
        leagues (where: { id: { eq: "${leagueId}"}}) {
          name
          startDate
          endDate
          teams {
            name
            users {
              activityHistories {
                duration
              }
            }
          }
        }
      }`,
        })
          .then((result: any) => {
            dispatch(setTeams(result.data.leagues[0].teams))
          })
          .catch((error: any) => {})
      })
      .catch((error: any) => {})
  }

  useEffect(() => {
    if (oidcUser !== null) {
      fetchData()
      dispatch(setUserId(oidcUser.sub))
    }
  }, [oidcUser])

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const useCompactTitle = useMediaQuery(theme.breakpoints.down("sm"))
  const matches = useMediaQuery(theme.breakpoints.up("md"))

  const renderLoginSection = () => {
    var fwe = isAuthenticated
    return (
      <Stack direction="row">
        {/* <IconButton sx={{ ml: 1 }} onClick={props.toggleColorMode}>
          {props.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
        <LanguagePopover /> */}
        <LoginSection />
        {matches && oidcUserLoadingState === OidcUserStatus.Loaded && (
          <AddActivity />
        )}
      </Stack>
    )
  }
  return (
    <AppBar
      className="top-appbar"
      sx={{
        backgroundColor: "#223155",
        zIndex: 1000,
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <Toolbar className="main-title">
        <Container maxWidth="lg">
          <Grid container spacing={0} style={{ marginTop: "-3px" }}>
            <Grid
              className="compact-title-box"
              item
              xs={4}
              md={3}
              mt={1}
              sx={{
                fontSize: "x-large",
                color: "text.primary",
                marginTop: "4px",
                whiteSpace: "nowrap",
              }}
            >
              {useCompactTitle ? (
                <>
                  <Box className="compact-title">Workout</Box>
                  <Box className="compact-title compact-title-sub">League</Box>
                </>
              ) : (
                <Box className="header-title">
                  <i>Workout League</i>
                </Box>
              )}
            </Grid>

            {!matches && (
              <Grid container item xs={8} md={4} justifyContent={"flex-end"}>
                {renderLoginSection()}
              </Grid>
            )}
            {oidcUserLoadingState === OidcUserStatus.Loaded && (
              <Grid
                className="menu-box"
                container
                item
                xs={12}
                md={5}
                sx={{ paddingBottom: "5px" }}
                justifyContent={"center"}
              >
                <Box>
                  <Link to={`/`} style={{ textDecoration: "none" }}>
                    <Button
                      type="button"
                      className="btn btn-primary"
                      sx={{ color: "text.primary", textTransform: "none" }}
                    >
                      <Stack>
                        <Box>
                          <HomeIcon fontSize="large" />
                        </Box>
                        <Box sx={{ marginTop: "-15px" }}>Home</Box>
                      </Stack>
                    </Button>
                  </Link>
                  <Link to={`/dashboard`} style={{ textDecoration: "none" }}>
                    <Button
                      type="button"
                      className="btn btn-primary"
                      sx={{ color: "text.primary", textTransform: "none" }}
                    >
                      <Stack>
                        <Box>
                          <DashboardIcon fontSize="large" />
                        </Box>
                        <Box sx={{ marginTop: "-15px" }}>Leaderboard</Box>
                      </Stack>
                    </Button>
                  </Link>
                  <Link to={`/team`} style={{ textDecoration: "none" }}>
                    <Button
                      type="button"
                      className="btn btn-primary"
                      sx={{ color: "text.primary", textTransform: "none" }}
                    >
                      <Stack>
                        <Box>
                          <Groups2Icon fontSize="large" />
                        </Box>
                        <Box sx={{ marginTop: "-15px" }}>My Team</Box>
                      </Stack>
                    </Button>
                  </Link>
                </Box>

                {/* <Link to={`/statistic`} style={{ textDecoration: "none" }}>
                  <Button
                    type="button"
                    className="btn btn-primary"
                    sx={{ color: "text.primary", textTransform: "none" }}
                  >
                    <Stack>
                      <Box>
                        <LocalLibraryIcon fontSize="large" />
                      </Box>
                      <Box sx={{ marginTop: "-15px" }}>Tips</Box>
                    </Stack>
                  </Button>
                </Link> */}
                <Box
                  className="add-button"
                  sx={{
                    marginLeft: "10px",
                    position: "relative",
                    bottom: "38px",
                    right: "0px",
                  }}
                >
                  <Button
                    sx={{
                      borderRadius: "50px",
                      width: "82px",
                      height: "82px",

                      background:
                        "linear-gradient(to top, #12c2e9, #c471ed, #f64f59)",
                    }}
                    type="button"
                    variant="contained"
                    onClick={handleOpenAddActivity}
                  >
                    <AddIcon fontSize="large" />
                  </Button>
                  <AddActivityDialog
                    open={openAddActivity}
                    onClose={handleCloseAddActivity}
                  />
                </Box>
              </Grid>
            )}

            <Grid container item xs={8} md={4} justifyContent={"flex-end"}>
              {matches && renderLoginSection()}
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  )
}
