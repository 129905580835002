import { useState, useEffect } from "react"
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera"
import { Box, Grid } from "@mui/material"

export const Camera = (props: any) => {
  const [preview, setPreview] = useState<any>()

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!props.selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(props.selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [props.selectedFile])

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      props.setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    props.setSelectedFile(e.target.files[0])
  }

  return (
    <Box
      sx={{
        backgroundColor: "#233257",
        height: "70px",
        width: "80px",
        borderRadius: "5px",
        border: "1px solid #32477D",
      }}
    >
      <Grid
        item
        container
        justifyContent={"center"}
        alignContent={"center"}
        sx={{
          height: "100%",
          overflow: "hidden",
          borderRadius: "5px",
        }}
      >
        {!props.selectedFile && (
          <>
            <label htmlFor="file-input">
              <PhotoCameraIcon fontSize="large" />
            </label>
            <input
              style={{ display: "none" }}
              id="file-input"
              type="file"
              accept="image/*"
              onChange={onSelectFile}
            />
          </>
        )}

        {props.selectedFile && <img src={preview} width={"100%"} />}
      </Grid>
    </Box>
  )
}
