import axios, { AxiosRequestConfig } from "axios"

export enum httpMethods {
  post = "POST",
  get = "GET",
  put = "PUT",
  delete = "DELETE",
}

enum ContentTypeEnum {
  ApplicationJson = "application/json",
  MultipartFormData = "multipart/form-data",
}

class Http {
  method: httpMethods
  url: string
  token: string
  client: Object
  data: Object
  body: Object
  contentType: ContentTypeEnum

  constructor() {
    this.method = httpMethods.post
    this.client = {}
    this.token = ""
    this.data = {}
    this.body = {}
    this.contentType = ContentTypeEnum.ApplicationJson
    this.url = "https://localhost:7197/graphql"
  }

  setMethod(method: httpMethods) {
    this.method = method
    return this
  }

  setEndpoint(url: string) {
    this.url = url
    return this
  }

  setToken(token: string) {
    this.token = `Bearer ${token}`
    return this
  }

  setPayload(data: object) {
    this.body = data
    return this
  }

  init = () => {
    this.token = JSON.parse(sessionStorage.getItem(`oidc.default:${origin}`)!)

    let headers = {
      Accept: "application/json",
      Authorization: "",
    }

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`
    }

    this.client = axios.create({
      baseURL: this.url,
      timeout: 31000,
      headers: headers,
    })

    return this.client
  }
  async request<T>(): Promise<T> {
    try {
      let url = this.url
      const headers: AxiosRequestConfig["headers"] = {
        "Content-Type": this.contentType,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        credentials: "include",
        ...(this.token ? { Authorization: this.token } : {}),
      }

      // const formData = new FormData()
      // var formattedData =
      //   this.method === httpMethods.post &&
      //   this.contentType === ContentTypeEnum.MultipartFormData
      //     ? formData
      //     : JSON.stringify(this.data) || {}

      const config: AxiosRequestConfig = {
        url,
        headers,
        method: this.method,
        data: this.body,
      }

      const response = await axios(config)

      return response.data as T
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw error.response.data
      }
      throw error
    }
  }
}

export default new Http()
