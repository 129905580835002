import { Box, Button, Card, CardContent, Grid } from "@mui/material"

export const LandingHome = () => {
  const authorityUrl = import.meta.env.VITE_AUTHORITY
  const callbackUrlEncoded = window.location.href
    .replace(":", "%253A")
    .replace("/", "%252F")

  return (
    <Card sx={{ minWidth: 275, height: "91vh" }}>
      <CardContent
        style={{
          overflow: "hidden",
          height: "100%",
          padding: "0",
          paddingBottom: "15px",
        }}
      >
        <Box sx={{ height: "100%" }}>
          <Grid
            sx={{
              height: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "100px",
              width: "85%",
              lineHeight: 1,
              position: "relative",
              zIndex: 2,
            }}
            container
            direction="row"
            justifyContent="center"
            fontSize={"45px"}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  color: "text.primary",
                  fontSize: "clamp(45px, 5.5vw, 80px)",
                  zIndex: "20",
                  position: "relative",
                }}
              >
                <center>
                  <strong>
                    Get in shape with your friends, while having fun!
                  </strong>
                </center>
              </Box>
              <Box
                sx={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "500",
                  color: "text.primary",
                  fontSize: "clamp(25px, 2.5vw, 48px)",
                  zIndex: "20",
                  position: "relative",
                }}
              >
                <a
                  href={`${authorityUrl}/account/register?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fredirect_uri%3D${callbackUrlEncoded}register%26client_id%3DWol.Front%26response_type%3Dcode%26state%3DqPIAKtcicL%26scope%3Dopenid%2520profile%26nonce%3DWogibYxVGVLI%26code_challenge%3DC3IN3ZnJPqRPgu7evqhAunVqd6uU5HMl3wDAboEi7JM%26code_challenge_method%3DS256`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    id="signup-btn-signup"
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: "10px" }}
                  >
                    Start Your League now
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  overflow: "hidden",
                  background:
                    "radial-gradient(47.3% 43.8% at 50% 42%,#63008a 0%,rgba(29,0,41,0) 100%)",
                  height: "913px",
                  width: "1200px",
                  zIndex: 0,
                  top: "calc(54.03225806451615% - 779px / 2)",
                  position: "absolute",
                  left: "calc(50.00000000000002% - min(1200px,1200px) / 2)",
                }}
              >
                <Box
                  sx={{
                    background:
                      "radial-gradient(50% 40% at 50% 40%,#12c2e9 0%,rgba(210,97,255,0) 100%)",
                    filter: "blur(10px)",
                    flex: "none",
                    height: "663px",
                    top: "30px",
                    left: "calc(61.66666666666669% - 634px / 2)",
                    opacity: ".7",
                    overflow: "hidden",
                    position: "absolute",
                    transform: "perspective(1200px)",
                    width: "634px",
                    zIndex: 1,
                  }}
                ></Box>
                <Box
                  sx={{
                    background:
                      "radial-gradient(50% 40% at 50% 40%,#f64f59 0%,rgba(210,97,255,0) 100%)",
                    filter: "blur(10px)",
                    flex: "none",
                    height: "706px",
                    left: "calc(34.16666666666669% - 706px / 2)",
                    opacity: ".7",
                    overflow: "hidden",
                    position: "absolute",
                    transform: "perspective(1200px)",
                    width: "706px",
                    zIndex: 1,
                  }}
                ></Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}
