import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface ActivityHistory {}

export interface ActivityHistoriesState {
  activityHistories: Array<ActivityHistory>
}

const initialState: ActivityHistoriesState = {
  activityHistories: [],
}

export const activityHistoriesSlice = createSlice({
  name: "activityHistories",
  initialState,
  reducers: {
    setActivityHistories: (
      state,
      action: PayloadAction<Array<ActivityHistory>>,
    ) => {
      state.activityHistories = action.payload
    },
  },
})

export const { setActivityHistories } = activityHistoriesSlice.actions

export const selectActivityHistories = (state: RootState) =>
  state.activityHistories.activityHistories

export default activityHistoriesSlice.reducer
