import { useOidcUser } from "@axa-fr/react-oidc"
import { Box, Card, CardContent, CardMedia, Grid, Stack } from "@mui/material"
import { JoinLeague } from "../league/joinLeague"
import { CreateLeague } from "../league/createLeague"
import { graphql } from "../../api/teamApi"
import { useEffect, useState } from "react"
import { LeaguePage } from "../league/leaguePage"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { LeagueMenu } from "./leagueMenu"
import { TeamMenu } from "./teamMenu"
import { TeamSubPage } from "./teamSubPage"
import kart from "../../static/images/kart.png"

export const TeamPage = (props: any) => {
  const { oidcUser, oidcUserLoadingState } = useOidcUser()
  const [data, setData] = useState<any>()
  const [team, setTeam] = useState<any>()
  const { id } = props.teams[0]
  const role = useSelector(
    (state: RootState) => state.league?.leagues[0]?.roles[0],
  )

  const fetchData = () => {
    graphql({
      query: `{
        teams(
          where: {id : { eq: "${id}"}}) {
          id,
          name
          code
          users {
            id,
            fullName
            firstName
            lastName
            pictureUrl
          },
          leagues {
            id,
            name
          }
        }
      }`,
    })
      .then((data) => {
        setData(data.data)
        setTeam(data.data.teams[0])
      })
      .catch((error) => {})
  }

  useEffect(() => {
    oidcUser && fetchData()
  }, [oidcUser])

  const renderMain = () => {
    return data?.teams[0].leagues.length === 0 ? (
      <>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ color: "text.primary" }}
        >
          <Grid item p={2} sx={{ fontSize: "large" }}>
            <center>This team is not part of a League yet.</center>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Stack direction={"row"} spacing={2}>
                <JoinLeague refresh={fetchData} team={data?.teams[0]} />
                <CreateLeague refresh={fetchData} team={data?.teams[0]} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </>
    ) : (
      <TeamSubPage team={data?.teams[0]} />
    )
  }

  const calculateAvatarPos = (index: number) => {
    var pos = -10 * index
    return pos
  }

  return (
    <Box sx={{ width: "100%", padding: "5px", marginTop: "10px" }}>
      <Card sx={{ minWidth: "100%" }}>
        <CardContent
          style={{
            background:
              "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
            overflow: "hidden",
            height: "100%",
            padding: "7px",
            paddingBottom: "15px",
          }}
        >
          <Grid item container>
            <Grid item xs={12}>
              <Stack direction={"row"}>
                <Box sx={{ fontSize: "xx-large", fontWeight: 700 }}>
                  {props.teams[0].name}
                </Box>
                <TeamMenu fetchData={props.fetchData} team={team} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Grid item container justifyContent={"flex-start"}>
                {data?.teams[0]?.leagues[0] && (
                  <>
                    <Stack>
                      <Stack direction={"row"}>
                        <Box>
                          Part of league&nbsp;
                          <i>
                            <strong>{data?.teams[0]?.leagues[0]?.name}</strong>
                          </i>
                        </Box>
                        {/* <Box sx={{ position: "relative", top: "-5px" }}>
                          <LeagueMenu fetchData={fetchData} />
                        </Box> */}
                      </Stack>
                    </Stack>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid pt={2}>
            {data?.teams[0].users.map((user: any, index: number) => {
              return user?.pictureUrl ? (
                <>
                  <img
                    src={user?.pictureUrl}
                    style={{
                      height: "33px",
                      position: "relative",
                      left: calculateAvatarPos(index),
                    }}
                    className="header-profile-picture-large"
                    alt={"UserImage"}
                  />
                </>
              ) : (
                <img
                  style={{
                    height: "33px",
                    position: "relative",
                    left: "-10px",
                  }}
                  src={`https://eu.ui-avatars.com/api/?background=random&name=${user?.fullName}&rounded=true&size=100&bolde=true`}
                  alt="UserImage"
                />
              )
            })}
            <Box sx={{ position: "relative", top: "-5px", left: "4px" }}>
              {data?.teams[0].users.length} members
            </Box>
          </Grid>
        </CardContent>
      </Card>

      {data && renderMain()}
    </Box>
  )
}
