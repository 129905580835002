import { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { joinLeagueByCode } from "../../api/leagueApi"
import { toast } from "react-toastify"
import { Check, Warning } from "../../utils/ToastContent"

export const JoinLeague = (props: any) => {
  const [open, setOpen] = useState(false)
  const { team } = props
  const teamId = useSelector((state: RootState) => state.team.id)
  const [code, setCode] = useState<any>()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleJoin = () => {
    joinLeagueByCode(code, teamId)
      .then((res) => {
        toast.success(<Check text={`League joined`} />)
        setOpen(false)
        props.refresh()
      })
      .catch((error) => {
        toast.error(<Warning text={`Invalid league code`} />)
      })
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Join a League
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Join a League</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box p={2}>Enter League code</Box>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Code"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" color="success" onClick={handleJoin}>
            Join League
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
