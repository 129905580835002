import { useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { Box, Button, Container, Grid, Stack } from "@mui/material"
import "./style.css"
import { JoinTeam } from "./joinTeam"
import { CreateTeam } from "./createTeam"
import { NeedToLogin } from "../login/needToLogin"
import { useOidcUser } from "@axa-fr/react-oidc"
import Api from "../../utils/http/apiHelper"
import { makeRequest } from "../../utils/http/http"
import { TeamPage } from "./teamPage"
import { graphql } from "../../api/teamApi"

export const JoinOrCreateTeam = () => {
  const [showJoin, setShowJoin] = useState(false)
  const [showCreate, setShowCreate] = useState(false)
  const { oidcUser, oidcUserLoadingState } = useOidcUser()
  const [data, setData] = useState<any>()

  const renderMain = () => {
    return data?.users[0].teams.length === 0 ? (
      <>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ color: "text.primary" }}
        >
          <Grid item p={2} sx={{ fontSize: "large" }}>
            <center>
              Hmm, it seems you don't have a team yet. Don't worry. We'll set
              you up in no time.
            </center>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Stack direction={"row"} spacing={2}>
                <JoinTeam refresh={fetchData} />
                <CreateTeam refresh={fetchData} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </>
    ) : (
      <TeamPage teams={data?.users[0].teams} fetchData={fetchData} />
    )
  }

  const fetchData = () => {
    graphql({
      query: `{
        users(
          where: {id : { eq: "${oidcUser.sub}"}}) {
          id,
          email,
          activityHistories{
            activity {
              name,
            }
            duration
          },
          teams {
            id
            name,
            users {
              id
            }
          }
        }
      }`,
    })
      .then((data) => {
        setData(data.data)
      })
      .catch((error) => {})
  }

  useEffect(() => {
    oidcUser && fetchData()
  }, [oidcUser])

  return (
    <Container maxWidth={false} sx={{ padding: 0, color: "text.primary" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid container item xs={12} justifyContent="center">
          <>
            {oidcUserLoadingState === "Unauthenticated" ? (
              <NeedToLogin returnUrl="team" />
            ) : (
              data && renderMain()
            )}
          </>
        </Grid>
      </Grid>
    </Container>
  )
}
