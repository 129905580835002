import { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { Box } from "@mui/material"
import { joinTeamByCode } from "../../api/teamApi"
import { toast } from "react-toastify"
import { Check, Warning } from "../../utils/ToastContent"
import { Link } from "react-router-dom"

export const JoinTeam = (props: any) => {
  const [open, setOpen] = useState(false)
  const [code, setCode] = useState<any>()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Join a Team
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Join a Team</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box p={2}>Enter Team code</Box>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Code"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Link
            to={`./join?teamCode=${code}`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" color="success">
              Find Team
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  )
}
