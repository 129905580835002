import { makeRequest } from "../utils/http/http"
import { configuration } from "../utils/oidc/configurations"

var backApiUri = configuration.back_api_uri

interface ActivityHistory {
  activity: string
  duration: number
  date: string
}

export const addActivityHistory = (
  activityId: any,
  duration: any,
  date: any,
  imageReference: string | undefined,
  comment: string | undefined,
) => {
  return makeRequest(`${backApiUri}/activity`, "POST", {
    activityId,
    duration,
    date,
    imageReference,
    comment,
  })
}
