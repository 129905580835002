import * as React from "react"
import Button from "@mui/material/Button"
import Avatar from "@mui/material/Avatar"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"
import PersonIcon from "@mui/icons-material/Person"
import AddIcon from "@mui/icons-material/Add"
import Typography from "@mui/material/Typography"
import { blue } from "@mui/material/colors"
import { AddActivityDialog } from "../activity/addActivityDialog"

export const AddActivity = () => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        sx={{
          borderRadius: "40px",
          width: "32px",
          height: "62px",

          background: "linear-gradient(to top, #12c2e9, #c471ed, #f64f59)",
        }}
        type="button"
        variant="contained"
        onClick={handleClickOpen}
      >
        <AddIcon fontSize="large" />
      </Button>
      <AddActivityDialog open={open} onClose={handleClose} />
    </>
  )
}
