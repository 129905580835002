import { Box, Grid, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import { getLeague } from "../../../api/leagueApi"
import { useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import { selectCurrent } from "../../../features/league/leagueSlice"
import { getUsersSummarized } from "../../../api/userApi"
import { getTeamSummarized } from "../../../api/teamApi"

export const UserTop = (props: any) => {
  const currentLeague = useSelector((state: RootState) => selectCurrent(state))
  const [data, setData] = useState<any>()

  const style = {
    padding: 6,
    opacity: "0.9",
    color: "#FFF",
    backgroundColor: "#000",
    borderRadius: "5px",
  }

  const fetchData = () => {
    getTeamSummarized(props.team.id!).then((result) => {
      setData(result)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {data && (
        <>
          <Grid
            container
            item
            columnSpacing={{ xs: 2 }}
            pt={2}
            sx={{ paddingLeft: "10px" }}
          >
            <Grid item xs={8}></Grid>

            <Grid item xs={2} sx={{ fontSize: "xx-small", color: "darkgrey" }}>
              Today
            </Grid>
            <Grid item xs={2} sx={{ fontSize: "xx-small", color: "darkgrey" }}>
              Total
            </Grid>
          </Grid>
          {data.map((user: any, index: number) => {
            return (
              <Grid
                container
                item
                columnSpacing={{ xs: 2 }}
                sx={{ paddingLeft: "10px" }}
              >
                <Grid item xs={1}>
                  #{index + 1}
                </Grid>
                <Grid item xs={1}>
                  <img
                    alt="userPicture"
                    src={user.pictureUrl}
                    className="header-profile-picture-small"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                >
                  <Box>{user.firstName}</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box>{user.todayDuration}</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box>{user.totalDuration}</Box>
                </Grid>
              </Grid>
            )
          })}
        </>
      )}
    </>
  )
}
