import { FaCheckCircle } from "react-icons/fa"
import { VscError } from "react-icons/vsc"
import { RiErrorWarningLine } from "react-icons/ri"

export const Check = (props: any) => (
  <div>
    <div className="container">
      <div className="row">
        <div
          className="col"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.text}
        </div>
      </div>
    </div>
  </div>
)

export const Error = (props: any) => (
  <div>
    <div className="container">
      <div className="row">
        <div
          className="col"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.text}
        </div>
      </div>
    </div>
  </div>
)

export const Warning = (props: any) => (
  <div>
    <div className="container">
      <div className="row">
        <div
          className="col"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.text}
        </div>
      </div>
    </div>
  </div>
)
