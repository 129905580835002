import React, { useState } from "react"
import { Route, Routes } from "react-router-dom"
import { ToastContainer, Slide } from "react-toastify"

import { Box, createTheme, ThemeProvider } from "@mui/material"
import "./index.css"
import "react-toastify/dist/ReactToastify.css"
import { useDispatch } from "react-redux"
import { OidcProvider } from "@axa-fr/react-oidc"
import { SignUpPage } from "./pages/signup/signUpPage"
import { Home } from "./pages/home/home"
import { Header } from "./components/header/header"
import { Login } from "./pages/login/login"
import { PaletteOptions } from "@mui/material"
import { configuration } from "./utils/oidc/configurations"
import { Dashboard } from "./pages/dashboard/dashboard"
import { JoinOrCreateTeam } from "./pages/team/joinOrCreateTeam"
import { Register } from "./pages/signup/register"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en.json"
import { TeamJoinPage } from "./pages/team/teamJoinPage"

const CustomLoading = () => <div>Loading</div>

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string
    }
  }
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

export const App = () => {
  const [isLoading] = useState(false)
  const dispatch = useDispatch()
  const [mode, setMode] = useState("dark")
  const primaryHeaderBackgroundColor = mode === "dark" ? "#0D162A" : "#fff"
  const primaryBackgroundColor = mode === "dark" ? "#0b1222" : "#F0F2F5"
  const secondaryBackgroundColor = mode === "dark" ? "#2e3948" : "#F2F2F2"
  const primaryPopoverBackgroundColor = mode === "dark" ? "#233257" : "#fff"
  const primaryColor = mode === "dark" ? "#fff" : "#483f3f"
  const secondaryColor = mode === "dark" ? "#fff" : "#fff"
  const primaryPaperBackgroundColor = mode === "dark" ? "#0D162A" : "#fff"
  const primaryCardContent = mode === "dark" ? "#233257" : "#fff"
  const primaryBorder = mode === "dark" ? `1px solid #ccc` : `1px solid #ccc`
  const primaryFooterBackgroundColor = mode === "dark" ? "#162036" : "#d2d3d6"
  const mainPrimaryColor = mode === "dark" ? "#2186FF" : "#185298"
  const mainSecondaryColor = mode === "dark" ? "#0000FF" : "#185298"

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"))
  }

  TimeAgo.addDefaultLocale(en)

  const theme = createTheme({
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: primaryColor,
            backgroundColor: primaryPopoverBackgroundColor,
            boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%)",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: primaryColor,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: primaryCardContent,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          text: {
            whiteSpace: "nowrap",
            "&:hover": {
              backgroundColor: secondaryBackgroundColor,
              color: primaryColor,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            color: "#fff",
            borderColor: "#1854a1",
            "&:hover": {
              backgroundColor: "#415DA3",
              color: "#ffffff",
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            color: primaryColor,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            height: "6px",
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: primaryColor,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0 1px 3px rgb(0 0 0 / 20%)",
            backgroundColor: primaryPopoverBackgroundColor,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "4px",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            backgroundColor: primaryCardContent,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: "#fff",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundColor: primaryPopoverBackgroundColor,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: primaryColor,
          },
        },
      },
    },
    palette: {
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
      background: {
        default: primaryBackgroundColor,
      },
      text: {
        primary: primaryColor,
        secondary: secondaryColor,
      },
    },
  })

  if (isLoading) {
    return (
      <div className="container bordered centered">
        <div>
          <i className="fas fa-spinner fa-spin fa-3x" />
        </div>
      </div>
    )
  }
  return (
    <>
      <OidcProvider configuration={configuration}>
        <ThemeProvider theme={theme}>
          <Box
            id="App"
            className="appwrapper"
            sx={{
              backgroundColor: "background",
              overflow: "hidden",
              paddingTop: "60px",
            }}
          >
            <div id="page-wrap">
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Slide}
              />
              <Header toggleColorMode={toggleColorMode} mode={mode} />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/team" element={<JoinOrCreateTeam />} />
                <Route path="/team/join" element={<TeamJoinPage />} />
              </Routes>
              {/* <Footer /> */}
            </div>
          </Box>
        </ThemeProvider>
      </OidcProvider>
    </>
  )
}
