import { Box, Button, CircularProgress, Grid, Stack } from "@mui/material"
import { useEffect, useState } from "react"
import { useSearchParams, Navigate, Link } from "react-router-dom"
import { getTeamByCode, joinTeamByCode } from "../../api/teamApi"
import { toast } from "react-toastify"
import { Check, Warning } from "../../utils/ToastContent"
import { TeamCard } from "./teamCard"
import { OidcUserStatus, useOidcUser } from "@axa-fr/react-oidc"
import { useDispatch } from "react-redux"
import { setReturnUrl } from "../../features/system/systemSlice"

export const TeamJoinPage = () => {
  const [searchParams] = useSearchParams()
  const [joinedTeam, setJoinedTeam] = useState(false)
  const [team, setTeam] = useState<any>()
  var teamCode = searchParams.get("teamCode")
  const [isLoading, setIsLoading] = useState(false)
  const { oidcUser, oidcUserLoadingState } = useOidcUser()
  const authorityUrl = import.meta.env.VITE_AUTHORITY
  const dispatch = useDispatch()
  const callbackUrlEncoded = window.location.origin
    .replace(":", "%253A")
    .replace("/", "%252F")

  const handleJoin = () => {
    joinTeamByCode(teamCode!)
      .then((res) => {
        toast.success(<Check text={`Team joined`} />)
        setJoinedTeam(true)
      })
      .catch((error) => {
        toast.error(<Warning text={`Invalid team code`} />)
      })
  }

  useEffect(() => {
    if (oidcUserLoadingState === OidcUserStatus.Loaded) {
      setIsLoading(true)
      getTeamByCode(teamCode!).then((res: any) => {
        sessionStorage.setItem(`returnUrl`, "")
        setTeam(res.data.teams[0])
        setIsLoading(false)
      })
    }
  }, [oidcUserLoadingState])

  if (oidcUserLoadingState === OidcUserStatus.Unauthenticated) {
    sessionStorage.setItem(`returnUrl`, `team/join?teamCode=${teamCode}`)
    return (
      <Grid item container justifyContent={"center"} sx={{ color: "white" }}>
        <Grid xs={8} pt={3}>
          <Stack spacing={2}>
            <center>In order to join a team you first need to register.</center>
            <Box>
              <a
                href={`${authorityUrl}/account/register?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fredirect_uri%3D${callbackUrlEncoded}%252Fregister%26client_id%3DWol.Front%26response_type%3Dcode%26state%3DqPIAKtcicL%26scope%3Dopenid%2520profile%26nonce%3DWogibYxVGVLI%26code_challenge%3DC3IN3ZnJPqRPgu7evqhAunVqd6uU5HMl3wDAboEi7JM%26code_challenge_method%3DS256`}
                style={{ textDecoration: "none" }}
              >
                <Button
                  id="signup-btn-signup"
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="success"
                  style={{ marginLeft: "10px" }}
                >
                  Register
                </Button>
              </a>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  if (joinedTeam) {
    return (
      <Navigate
        to={{
          pathname: "../team",
        }}
      />
    )
  }

  if (isLoading) {
    return (
      <Grid item xs={12} justifyContent={"center"} container pt={5}>
        <CircularProgress />
      </Grid>
    )
  }

  if (team !== undefined)
    return (
      <Grid container pt={3} spacing={3}>
        <Grid item xs={12} justifyContent={"center"} container>
          <Box sx={{ color: "#fff" }}>
            <TeamCard team={team} teamCode={teamCode} />
          </Box>
        </Grid>
        <Grid item xs={12} justifyContent={"center"} container>
          <Button variant="contained" color="success" onClick={handleJoin}>
            Join this team
          </Button>
        </Grid>
      </Grid>
    )
}
