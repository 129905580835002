import { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { toast } from "react-toastify"
import { Check, Error } from "../../utils/ToastContent"
import { createLeague, joinLeague } from "../../api/leagueApi"
import { useAppDispatch } from "../../app/hooks"
import { setLeagueId } from "../../features/league/leagueSlice"
import { getUserInfo } from "../../api/userApi"
import { RootState } from "../../app/store"
import { useSelector } from "react-redux"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Stack } from "@mui/material"

export const CreateLeague = (props: any) => {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState<string>("")
  const { team } = props
  const dispatch = useAppDispatch()
  const userId = useSelector((state: RootState) => state.user.id)
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    dayjs(new Date()),
  )
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs(new Date()))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submit = () => {
    createLeague(name, startDate, endDate)
      .then((data) => {
        joinLeague(data.id, team.id).then((res) => {
          dispatch(setLeagueId(data.id))
          getUserInfo(userId, team.id, data.id)
          toast.success(<Check text={`League created`} />)
          setOpen(false)
          props.refresh()
        })
      })
      .catch((error) => {
        toast.error(<Error text={`Could not create league`} />)
      })
  }

  return (
    <div>
      <Button variant="outlined" color="success" onClick={handleClickOpen}>
        Create a League
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create a League</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="League name"
                type="email"
                fullWidth
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <DatePicker
                label="Start Date"
                value={startDate}
                closeOnSelect
                onChange={(newValue) => setStartDate(newValue)}
                format="DD.MM.YYYY"
              />
              <DatePicker
                label="End Date"
                value={endDate}
                closeOnSelect
                onChange={(newValue) => setEndDate(newValue)}
                format="DD.MM.YYYY"
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submit} variant="contained" color="success">
            Create League
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
