import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Stack,
} from "@mui/material"

export const TeamCard = (props: any) => {
  return (
    <Box sx={{ width: "90vw", padding: "5px", marginTop: "10px" }}>
      <Card sx={{ minWidth: "100%" }}>
        <CardMedia
          component="img"
          height="140"
          sx={{
            opacity: 0.8,
            top: 0,
            right: 0,
            height: "100%",
            width: "100%",
          }}
        />
        <CardContent
          style={{
            overflow: "hidden",
            height: "100%",
            padding: "7px",
            paddingBottom: "15px",
          }}
        >
          <Grid item container>
            <Grid item xs={12}>
              <Stack direction={"row"}>
                <Box sx={{ fontSize: "xx-large", fontWeight: 700 }}>
                  {props.team.name}
                </Box>
                {/* <TeamMenu fetchData={props.fetchData} team={team} /> */}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Grid item container justifyContent={"flex-start"}>
                {props.team?.leagues[0] && (
                  <>
                    <Stack>
                      <Stack direction={"row"}>
                        <Box>
                          Part of league&nbsp;
                          <i>
                            <strong>{props.team?.leagues[0]?.name}</strong>
                          </i>
                        </Box>
                        {/* <Box sx={{ position: "relative", top: "-5px" }}>
                          <LeagueMenu fetchData={fetchData} />
                        </Box> */}
                      </Stack>
                    </Stack>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid pt={2}>
            {props.team.users.map((user: any) => {
              return user?.pictureUrl ? (
                <>
                  <img
                    src={user?.pictureUrl}
                    style={{
                      height: "33px",
                    }}
                    className="header-profile-picture-large"
                    alt={"UserImage"}
                  />
                </>
              ) : (
                <img
                  style={{
                    height: "33px",
                  }}
                  src={`https://eu.ui-avatars.com/api/?background=random&name=${user?.fullName}&rounded=true&size=100&bolde=true`}
                  alt="UserImage"
                />
              )
            })}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}
