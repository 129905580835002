import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice"
import userReducer from "../features/user/userSlice"
import leagueReducer from "../features/league/leagueSlice"
import teamReducer from "../features/team/teamSlice"
import systemReducer from "../features/system/systemSlice"
import activityHistoriesReducer from "../features/activityHistories/activityHistoriesSlice"

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    league: leagueReducer,
    team: teamReducer,
    system: systemReducer,
    activityHistories: activityHistoriesReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
