import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface RoleItem {
  name: string
}

export interface League {
  id: string
  name: string
  startDate: string
  endDate: string
  roles: Array<RoleItem>
}

export interface LeagueState {
  id: string
  leagues: Array<League>
  current: League | null
}

const initialState: LeagueState = {
  id: "",
  leagues: [],
  current: null,
}

export const leagueSlice = createSlice({
  name: "league",
  initialState,
  reducers: {
    setLeagueId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setLeagues: (state, action: PayloadAction<Array<League>>) => {
      state.leagues = action.payload
    },
    setCurrentLeague: (state, action: PayloadAction<League>) => {
      state.current = action.payload
    },
  },
})

export const { setLeagueId, setLeagues, setCurrentLeague } = leagueSlice.actions

export const selectLeagueId = (state: RootState) => state.league.id
export const selectCurrent = (state: RootState) => state.league.current

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount))
//     }
//   }

export default leagueSlice.reducer
