import { Box, Grid, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import { getLeague } from "../../../api/leagueApi"
import { useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import { selectCurrent } from "../../../features/league/leagueSlice"
import { getUsersSummarized } from "../../../api/userApi"

export const UserTop = (props: any) => {
  const currentLeague = useSelector((state: RootState) => selectCurrent(state))
  const [data, setData] = useState<any>()

  const style = {
    padding: 6,
    opacity: "0.9",
    color: "#FFF",
    backgroundColor: "#000",
    borderRadius: "5px",
  }

  const fetchData = () => {
    getUsersSummarized(currentLeague?.id!).then((result) => {
      setData(result)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const renderFont = (index: number) => {
    if (index === 0) return "larger"
    if (index === 1) return "large"
    else return "medium"
  }

  const renderProfilePic = (index: number) => {
    if (index === 0) return "27px"
    if (index === 1) return "24px"
    else return "20px"
  }

  return (
    <>
      {data && (
        <>
          <Grid
            container
            item
            columnSpacing={{ xs: 2 }}
            sx={{ paddingLeft: "10px" }}
          >
            <Grid item xs={9}></Grid>

            <Grid item xs={1} sx={{ fontSize: "xx-small", color: "darkgrey" }}>
              Today
            </Grid>
            <Grid item xs={1} sx={{ fontSize: "xx-small", color: "darkgrey" }}>
              Total
            </Grid>
          </Grid>
          {data.map((user: any, index: number) => {
            return (
              <Grid
                container
                item
                columnSpacing={{ xs: 2 }}
                sx={{
                  paddingLeft: "10px",
                  marginBottom: "5px",
                  fontSize: `${renderFont(index)}`,
                  fontWeight: `${index === 0 ? "700" : "600"}`,
                }}
              >
                <Grid item xs={1}>
                  #{index + 1}
                </Grid>
                <Grid item xs={1} container justifyContent={"center"}>
                  <img
                    alt="userPicture"
                    src={user.pictureUrl}
                    className="header-profile-picture-small"
                    style={{ height: `${renderProfilePic(index)}` }}
                  />
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                >
                  <Stack spacing={0}>
                    <Box>{user.firstName}</Box>
                    <Box
                      sx={{
                        color: "#2F4E94",
                        fontWeight: "400",
                        fontSize: "small",
                        position: "relative",
                      }}
                    >
                      {user.teams[0].name}
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={1}>
                  <Box>{user.todayDuration}</Box>
                </Grid>
                <Grid item xs={1}>
                  <Box>{user.totalDuration}</Box>
                </Grid>
              </Grid>
            )
          })}
        </>
      )}
    </>
  )
}
