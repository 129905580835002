import { Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  LabelList,
} from "recharts"
import { getLeague } from "../../../api/leagueApi"
import { useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import { selectCurrent } from "../../../features/league/leagueSlice"

export const TeamStats = (props: any) => {
  const currentLeague = useSelector((state: RootState) => selectCurrent(state))
  const [league, setLeague] = useState<any>()

  const style = {
    padding: 6,
    opacity: "0.9",
    color: "#FFF",
    backgroundColor: "#000",
    borderRadius: "5px",
  }

  const CustomTooltip = (props: any) => {
    const { active, payload } = props
    if (active) {
      const currData = payload && payload.length ? payload[0].payload : null

      return (
        <div style={style}>
          <Stack>
            <div>
              {"Team : "}
              <em>{currData ? currData.name : " -- "}</em>
            </div>
            <div>
              {"Total duration : "}
              <em>{currData ? currData.totalDuration : " -- "}</em>
            </div>
          </Stack>
        </div>
      )
    }

    return null
  }

  const fetchData = () => {
    getLeague(currentLeague?.id!).then((result) => {
      setLeague(result)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, value } = props
    return (
      <g>
        <text
          x={width + 15}
          y={y + 11}
          fill="#fff"
          textAnchor="right"
          dominantBaseline="top"
        >
          {value}
        </text>
      </g>
    )
  }

  return (
    <>
      {league && (
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            layout="vertical"
            width={500}
            height={400}
            data={league.teams}
            margin={{
              top: 0,
              right: 20,
              bottom: 40,
              left: -50,
            }}
          >
            <XAxis type="number" domain={[0, "dataMax + 100"]} />
            <YAxis dataKey="name" type="category" tick={false} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="totalDuration" barSize={12} fill="#185298">
              <LabelList dataKey="name" content={renderCustomizedLabel} />
            </Bar>
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  )
}
