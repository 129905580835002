import { Button, Stack, Box } from "@mui/material"
import { UserPopover } from "./userPopover"
import { useOidcUser } from "@axa-fr/react-oidc"
import { Link } from "react-router-dom"

export const LoginSection = () => {
  const authorityUrl = import.meta.env.VITE_AUTHORITY
  const callbackUrlEncoded = window.location.href
    .replace(":", "%253A")
    .replace("/", "%252F")
  const { oidcUser, oidcUserLoadingState } = useOidcUser()

  return (
    <>
      {(oidcUserLoadingState === "Loading user" ||
        oidcUserLoadingState === "User loaded") && (
        <UserPopover oidcUser={oidcUser} />
      )}
      {oidcUserLoadingState === "Unauthenticated" && (
        <Stack direction="row" alignItems="center" sx={{ marginRight: "9px" }}>
          <Link to={`/login?returnUrl=home`} style={{ textDecoration: "none" }}>
            <Button
              size="small"
              id="signup-btn-signup"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Sign&nbsp;In
            </Button>
          </Link>
          <Box className="register-button">
            <a
              href={`${authorityUrl}/account/register?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fredirect_uri%3D${callbackUrlEncoded}register%26client_id%3DWol.Front%26response_type%3Dcode%26state%3DqPIAKtcicL%26scope%3Dopenid%2520profile%26nonce%3DWogibYxVGVLI%26code_challenge%3DC3IN3ZnJPqRPgu7evqhAunVqd6uU5HMl3wDAboEi7JM%26code_challenge_method%3DS256`}
              style={{ textDecoration: "none" }}
            >
              <Button
                size="small"
                id="signup-btn-signup"
                type="submit"
                fullWidth
                variant="outlined"
                color="primary"
                style={{ marginLeft: "10px" }}
              >
                Register
              </Button>
            </a>
          </Box>
        </Stack>
      )}
    </>
  )
}
