import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface SystemState {
  returnUrl: string
}

const initialState: SystemState = {
  returnUrl: "",
}

export const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    setReturnUrl: (state, action: PayloadAction<string>) => {
      state.returnUrl = action.payload
    },
  },
})

export const { setReturnUrl } = systemSlice.actions

export const selectReturnUrl = (state: RootState) => state.system.returnUrl

export default systemSlice.reducer
