import { OidcSecure } from "@axa-fr/react-oidc"
import { Navigate, useParams, useSearchParams } from "react-router-dom"

export const Login = () => {
  const [searchParams] = useSearchParams()

  var returnUrl = searchParams.get("returnUrl")
  return (
    <OidcSecure>
      <div>
        <Navigate to={`/${returnUrl}`} replace={true} />
      </div>
    </OidcSecure>
  )
}
