import * as React from "react"
import Box from "@mui/material/Box"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import LogoutIcon from "@mui/icons-material/Logout"
import { leaveLeague } from "../../api/leagueApi"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { leaveTeam } from "../../api/teamApi"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { CopyToClipboard } from "react-copy-to-clipboard"

export const TeamMenu = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const teamId = useSelector((state: RootState) => state.team.id)
  const teamLink =
    window.location.protocol +
    `//` +
    window.location.hostname +
    `/team/join?teamCode=${props.team?.code}`

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLeave = () => {
    leaveTeam(teamId).then((res) => {
      props.fetchData()
      setAnchorEl(null)
    })
  }

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLeave}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Leave this team
        </MenuItem>
        {props.team?.code && (
          <MenuItem>
            <ListItemIcon>
              <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <CopyToClipboard text={teamLink}>
              <span>Copy team link</span>
            </CopyToClipboard>
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}
