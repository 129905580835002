import { Card, Grid, Stack } from "@mui/material"
import { UserTop } from "./userTop/userTop"

export const TeamSubPage = (props: any) => {
  const renderUserTop = () => {
    return <UserTop team={props.team} />
  }

  const renderUserCards = () => {
    return (
      <Grid pt={2}>
        <Stack direction={"row"} spacing={1}>
          {props.team.users.map((user: any) => {
            return (
              <Grid item container xs={4}>
                <Card
                  sx={{
                    width: "100%",
                    padding: "7px",
                    border: "1px solid #32477d",
                  }}
                >
                  <Grid item container justifyContent={"center"}>
                    {user?.pictureUrl ? (
                      <>
                        <img
                          src={user?.pictureUrl}
                          style={{
                            height: "33px",
                          }}
                          className="header-profile-picture-large"
                          alt={"UserImage"}
                        />
                      </>
                    ) : (
                      <img
                        src={`https://eu.ui-avatars.com/api/?background=random&name=${user?.fullName}&rounded=true&size=100&bolde=true`}
                        alt="UserImage"
                      />
                    )}
                  </Grid>
                  <Grid item container justifyContent={"center"}>
                    {user.firstName}
                  </Grid>
                </Card>
              </Grid>
            )
          })}
        </Stack>
      </Grid>
    )
  }

  return (
    <>
      {renderUserCards()}
      {renderUserTop()}
    </>
  )
}
