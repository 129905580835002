import { useEffect, useState } from "react"
import { registerUser } from "../../api/userApi"
import { Navigate, useSearchParams } from "react-router-dom"
import { OidcSecure, useOidcUser } from "@axa-fr/react-oidc"
import { RootState } from "../../app/store"
import { selectReturnUrl } from "../../features/system/systemSlice"
import { useSelector } from "react-redux"

export const Register = () => {
  const [searchParams] = useSearchParams()
  const [isRegistered, setIsRegistered] = useState(false)
  const { oidcUser, oidcUserLoadingState } = useOidcUser()

  useEffect(() => {
    if (oidcUser !== null) {
      var user = {
        Id: oidcUser.sub,
        FirstName: oidcUser.given_name,
        LastName: oidcUser.family_name,
        Email: oidcUser.email,
        PictureUrl: oidcUser.picture,
      }
      registerUser(user).then(() => {
        setIsRegistered(true)
      })
    }
  }, [oidcUser])

  if (isRegistered) {
    const returnUrl = sessionStorage.getItem(`returnUrl`)
    if (returnUrl !== "") {
      return (
        <Navigate
          to={{
            pathname: "/login",
            search: `returnUrl=${returnUrl}`,
          }}
        />
      )
    } else {
      return (
        <Navigate
          to={{
            pathname: "/login",
            search: "returnUrl=home",
          }}
        />
      )
    }
  } else
    return (
      <OidcSecure>
        <></>
      </OidcSecure>
    )
}
