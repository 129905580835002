import { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import { Box } from "@mui/material"
import { graphql } from "../../api/teamApi"

const filter = createFilterOptions<ActivityOptionType>()

export const SearchActivity = (props: any) => {
  const [value, setValue] = useState<ActivityOptionType | null>(null)
  const [open, toggleOpen] = useState(false)
  const [data, setData] = useState<ActivityOptionType[]>()

  const fetchData = () => {
    graphql({
      query: `{
        activities {
          id,
          name
        }
      }`,
    })
      .then((res) => {
        setData(res.data.activities)
      })
      .catch((error) => {})
  }

  const handleClose = () => {
    setDialogValue({
      name: "",
      id: "",
    })
    toggleOpen(false)
  }

  const [dialogValue, setDialogValue] = useState({
    name: "",
    id: "",
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setValue({
      name: dialogValue.name,
      id: dialogValue.id,
    })
    handleClose()
  }

  useEffect(() => {
    fetchData()
  }, [])

  const dataOptions: readonly ActivityOptionType[] = data!

  return (
    <Box>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true)
              setDialogValue({
                name: newValue,
                id: newValue,
              })
            })
          } else {
            setValue(newValue)
            props.setActivity(newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          return filtered
        }}
        id="free-solo-dialog-demo"
        options={dataOptions}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.name
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) => <TextField {...params} label="Activity" />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new activity</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any film in our list? Please, add it!
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="title"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  )
}

interface ActivityOptionType {
  inputValue?: string
  name: string
  id: string
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
