import React from "react"
import { Container, Grid } from "@mui/material"
import { OidcUserStatus, useOidcUser } from "@axa-fr/react-oidc"
import { LandingHome } from "./landingHome"
import { Leaderboard } from "./leaderboard"

export const Home = () => {
  const { oidcUserLoadingState } = useOidcUser()

  return (
    <>
      <Container maxWidth={false} sx={{ padding: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            {oidcUserLoadingState !== OidcUserStatus.Loaded && <LandingHome />}
            {oidcUserLoadingState === OidcUserStatus.Loaded && <Leaderboard />}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
