import React from "react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { Button, Grid, IconButton, Popover, Stack } from "@mui/material"
import Divider from "@mui/material/Divider"
import { useOidcUser, useOidc } from "@axa-fr/react-oidc"
import "./style.css"
import { Box } from "@mui/system"
import { Link } from "react-router-dom"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import SettingsIcon from "@mui/icons-material/Settings"
import SecurityIcon from "@mui/icons-material/Security"

export const UserPopover = (props: any) => {
  const { logout, isAuthenticated } = useOidc()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const { oidcUser, oidcUserLoadingState } = useOidcUser()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = (event: any) => {
    logout().then(() => {
      window.location.href = "/"
      // window.location.href = `${authority}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}`
    })
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const renderAdminSettings = () => {
    return (
      <div className="row">
        <div className="col profile-name-box profile-box-center">
          <Link to={`/configuration`} style={{ textDecoration: "none" }}>
            <Button
              className="no-outline"
              size="large"
              startIcon={<SecurityIcon />}
              onClick={handleClose}
            >
              Configuration
            </Button>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-label="delete"
        style={{ color: "white" }}
      >
        {oidcUser?.picture !== undefined ? (
          <img
            src={oidcUser?.picture}
            className="header-profile-picture"
            alt={"UserImage"}
          />
        ) : (
          <AccountCircleIcon />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid
          item
          container
          justifyContent={"center"}
          sx={{ maxWidth: "250px" }}
        >
          <Box sx={{ border: "border" }} className="user-popover">
            <Stack spacing={1}>
              <Grid item container justifyContent={"center"}>
                {oidcUser?.picture ? (
                  <img
                    src={oidcUser?.picture}
                    className="header-profile-picture-large"
                    alt={"UserImage"}
                  />
                ) : (
                  <img
                    src={`https://eu.ui-avatars.com/api/?background=random&name=${oidcUser?.name}&rounded=true&size=100&bolde=true`}
                    alt="UserImage"
                  />
                )}
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Grid item>
                  <strong>{oidcUser?.name ?? "Loading ..."}</strong>
                </Grid>
                <Grid item>{oidcUser?.email}</Grid>
              </Grid>
              <Divider variant="middle" style={{ borderColor: "#185298" }} />
              <Box sx={{ m: 2 }}>
                <Grid item container justifyContent={"center"}>
                  <Grid item>
                    <Link to={`/settings`} style={{ textDecoration: "none" }}>
                      <Button size="large" startIcon={<SettingsIcon />}>
                        Settings
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <Grid item container justifyContent={"center"}>
                  <Grid item>
                    <Button
                      size="large"
                      startIcon={<ExitToAppIcon />}
                      onClick={(e) => handleLogOut(e)}
                    >
                      Log Out
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Divider variant="middle" style={{ borderColor: "#185298" }} />
              <Box sx={{ m: 2 }}>
                <div
                  style={{
                    fontSize: "x-small",
                    display: "flex",
                    justifyContent: "center",
                    color: "#666",
                  }}
                >
                  <strong>v.: test</strong>
                </div>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Popover>
    </>
  )
}
