import { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { createTeam, joinTeam } from "../../api/teamApi"
import { toast } from "react-toastify"
import { Check } from "../../utils/ToastContent"

export const CreateTeam = (props: any) => {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState<string>("")

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submit = () => {
    createTeam(name)
      .then((data) => {
        joinTeam(data.id).then((res) => {
          toast.success(<Check text={`Team created`} />)
          setOpen(false)
          props.refresh()
        })
      })
      .catch((error) => {})
  }

  return (
    <div>
      <Button variant="outlined" color="success" onClick={handleClickOpen}>
        Create a Team
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create a Team</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We need some info to set up the team
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Team name"
            type="email"
            fullWidth
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={submit} variant="contained" color="success">
            Create Team
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
