import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { string } from "prop-types"

export interface User {
  id: string
  name: string
}
export interface Team {
  id: string
  name: string
  roles: []
  users: User[]
}
export interface TeamState {
  id: string
  teams: Team[]
  users: User[]
}

const initialState: TeamState = {
  id: "",
  teams: [],
  users: [],
}

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeamId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setTeams: (state, action: PayloadAction<Team[]>) => {
      state.teams = action.payload
    },
  },
})

export const { setTeamId, setTeams } = teamSlice.actions
export const selectId = (state: RootState) => state.team.id
export const selectTeams = (state: RootState) => state.team.teams
export default teamSlice.reducer
