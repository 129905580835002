import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { motion } from "framer-motion"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Box } from "@mui/system"
import TurnRightIcon from "@mui/icons-material/TurnRight"
import TurnLeftIcon from "@mui/icons-material/TurnLeft"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { useSelector } from "react-redux"
import { RootState } from "../../../app/store"
import { selectTeams } from "../../../features/team/teamSlice"

export const ProgressTracker = (props: any) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const teams = useSelector((state: RootState) => selectTeams(state))

  const calcDaysLeft = () => {
    var start = new Date(props.currentLeague?.startDate),
      end = new Date(props.currentLeague?.endDate),
      today = new Date()
    var todayTime = new Date().getTime()
    var endTime = end.getTime()
    var diffDays =
      Math.round(((endTime - todayTime) / (1000 * 3600 * 24)) * 1) / 1
    return diffDays
  }

  const calcDaysTotal = () => {
    var start = new Date(props.currentLeague?.startDate),
      end = new Date(props.currentLeague?.endDate)
    var startTime = start.getTime()
    var endTime = end.getTime()
    var diffDays =
      Math.round(((endTime - startTime) / (1000 * 3600 * 24)) * 1) / 1
    return diffDays
  }

  const calcDaysGone = () => {
    var start = new Date(props.currentLeague?.startDate),
      end = new Date(props.currentLeague?.endDate),
      today = new Date()
    // Convert to unix values timestamp values
    var startDate = start.getTime()
    var endDate = end.getTime()
    var todayDate = today.getTime()

    // Get the total possible timestamp value
    var total = endDate - startDate

    // Get the current value
    var current = todayDate - startDate
    // Get the percentage
    return (current / total) * 100
  }

  useEffect(() => {
    calcDaysGone()
  }, [])

  return (
    <motion.div
      style={{ width: "100%" }}
      onClick={() => setIsExpanded(!isExpanded)}
      className="progress-tracker"
    >
      <Card sx={{ width: "100%" }}>
        <CardActionArea>
          <CardContent>
            <Grid container item xs={12} justifyContent="flex-end" p={0}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  {props.currentLeague?.name}
                </Grid>
                <Grid sx={{ marginTop: "7px" }} item xs={12}>
                  <LinearProgress
                    className="progress-tracker"
                    variant="determinate"
                    value={calcDaysGone()}
                    sx={{ height: `${isExpanded === true ? "13px" : "7px"}` }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {isExpanded && (
              <>
                <motion.div
                  layout
                  transition={{ delay: 0.1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <Grid container item xs={12} justifyContent="flex-end" p={0}>
                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ position: "relative", left: "-10px" }}
                    >
                      <Grid item xs={6}>
                        <Stack direction={"row"}>
                          <Box>
                            <TurnRightIcon
                              style={{ transform: "rotate(-90deg)" }}
                            />
                          </Box>
                          <Box sx={{ position: "relative", top: "8px" }}>
                            {new Date(
                              props.currentLeague?.startDate,
                            ).toLocaleDateString("no-NO", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid container item xs={6} justifyContent={"flex-end"}>
                        <Stack
                          direction={"row"}
                          sx={{ position: "relative", right: "-20px" }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              top: "8px",
                              right: "1px",
                            }}
                          >
                            {new Date(
                              props.currentLeague?.endDate,
                            ).toLocaleDateString("no-NO", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </Box>
                          <Box>
                            <TurnLeftIcon
                              style={{ transform: "rotate(90deg)" }}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </motion.div>

                <motion.div
                  layout
                  transition={{ delay: 0.1 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <Grid container item xs={12} p={1} fontSize={"medium"}>
                    <Grid item xs={12}>
                      <CalendarMonthIcon style={{ fontSize: "medium" }} />{" "}
                      {calcDaysLeft()} days left ({calcDaysTotal()} days total)
                    </Grid>
                    <Grid item xs={12}>
                      Teams:{" "}
                      {teams.map((team: any, index: number) => {
                        return `${team.name}${
                          index === teams.length - 1 ? "" : ","
                        } `
                      })}
                    </Grid>
                  </Grid>
                </motion.div>
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </motion.div>
  )
}
