import { useOidcUser } from "@axa-fr/react-oidc"
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  Stack,
  Box,
  Tooltip,
  CardMedia,
  LinearProgress,
} from "@mui/material"
import { NeedToLogin } from "../login/needToLogin"
import { useEffect, useState } from "react"
import { graphql } from "../../api/teamApi"
import {
  selectCurrent,
  selectLeagueId,
} from "../../features/league/leagueSlice"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import { TeamCard } from "./teamCard"
import ReactTimeAgo from "react-time-ago"
import Groups2Icon from "@mui/icons-material/Groups2"
import StartIcon from "@mui/icons-material/Start"
import { ProgressTracker } from "./progressTracker/progressTracker"
import { Progress } from "./testing"
import { TeamStats } from "./teamStats/teamStats"
import { CalendarStats } from "./calendar/calendarStats"
import { UserTop } from "./userTop/userTop"
import { TeamScatter } from "./teamStats/teamScatter"

export const Dashboard = () => {
  const { oidcUser, oidcUserLoadingState } = useOidcUser()
  const leagueId = useSelector((state: RootState) => selectLeagueId(state))
  const currentLeague = useSelector((state: RootState) => selectCurrent(state))
  const [activityHistories, setActivityHistories] = useState<any>()
  const [teamsData, setTeamsData] = useState<any>()
  const data = [
    { name: "Blue", count: 100 },
    { name: "Red", count: 50 },
  ]

  const fetchTeamsData = () => {
    graphql({
      query: `{
    leagues (where: { id: { eq: "${leagueId}"}}) {
      name
      startDate
      endDate
      teams {
        name
        code
        users {
          activityHistories {
            duration
          }
        }
      }
    }
  }`,
    })
      .then((result: any) => {
        setTeamsData(result.data?.leagues[0]?.teams)
      })
      .catch((error: any) => {})
  }

  const fetchData = () => {
    graphql({
      query: `{
        activityHistories 
        (order: [{date: DESC}]
          where: {
            user: {
              teams: {
                some: {
                  leagues: {
                    some: {
                      id: {
                        eq: "${leagueId}"
                        }
                    }
                  }
                }
              }
            }
          }) {
        date,
        duration,
        imageReference,
        user {
          fullName,
          pictureUrl,
          teams {
            name,
          }
        },
        activity {
        name,
      
      }
        } 
      }`,
    })
      .then((data: any) => {
        setActivityHistories(data.data.activityHistories)
      })
      .catch((error: any) => {})
  }

  useEffect(() => {
    oidcUser && fetchData()
    oidcUser && fetchTeamsData()
  }, [leagueId, oidcUser])

  const renderCard = (item: any) => {
    return (
      <Card sx={{ minWidth: 110, height: 140 }}>
        <CardActionArea>
          {item.imageReference && (
            <CardMedia
              style={{ opacity: "0.7" }}
              component="img"
              height="140"
              image={`../uploads/${item.imageReference}`}
              alt="skates"
            />
          )}
          <Box
            py={1}
            px={1}
            sx={{
              position: "absolute",
              zIndex: 2,
              top: 0,
              width: "100%",
            }}
          >
            <Stack>
              <Box>
                <Stack direction={"row"}>
                  <Tooltip title={item?.fullName}>
                    {item.user.pictureUrl ? (
                      <img
                        src={item?.user.pictureUrl}
                        className="header-profile-picture"
                        alt={"UserImage"}
                      />
                    ) : (
                      <img
                        src={`https://eu.ui-avatars.com/api/?background=random&name=${item?.user?.fullName}&rounded=true&size=100&bolde=true`}
                        className="header-profile-picture"
                        alt={"UserImage"}
                      />
                    )}
                  </Tooltip>
                  <Box sx={{ paddingLeft: "5px" }}>
                    <Stack>
                      <Box sx={{ fontSize: "xx-small" }}>
                        <ReactTimeAgo date={item.date} locale="en-US" />
                      </Box>
                      <Box sx={{ fontSize: "x-small" }}>
                        <Box>{item.user.teams[0].name}</Box>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Box sx={{ position: "relative", bottom: "-55px" }}>
                <Box>{item.activity.name} </Box>
                <Box>{item.duration} mins</Box>
              </Box>
            </Stack>
          </Box>
        </CardActionArea>
      </Card>
    )
  }

  const renderMain = () => {
    return (
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <ProgressTracker currentLeague={currentLeague} />
          {/* <Grid
            container
            item
            xs={12}
            justifyContent="center"
            pt={1}
            pl={1}
            sx={{ overflow: "scroll", marginTop: "5px" }}
          >
            <Stack direction={"row"} spacing={1} sx={{ overflow: "scroll" }}>
              {activityHistories.map((item: any) => {
                return renderCard(item)
              })}
            </Stack>
          </Grid> */}
        </Grid>
        <Grid
          pt={3}
          sx={{ height: "180px" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          {/* <TeamScatter data={data} /> */}
          <TeamStats data={data} />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          sx={{ marginBottom: "30px" }}
        >
          <UserTop />
        </Grid>
        {/* <Grid
          sx={{ height: "150px" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <CalendarStats />
        </Grid> */}
      </>
    )
  }

  return (
    <Container
      maxWidth={false}
      sx={{ padding: 0, color: "text.primary", marginBottom: "140px" }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid container item xs={12} justifyContent="center">
          <>
            {oidcUserLoadingState === "Unauthenticated" ? (
              <NeedToLogin />
            ) : (
              activityHistories && renderMain()
            )}
          </>
        </Grid>
      </Grid>
    </Container>
  )
}
