import { makeRequest } from "../utils/http/http"
import { configuration } from "../utils/oidc/configurations"
import Http, { httpMethods } from "../utils/http/apiHelper"
import { League } from "../features/league/leagueSlice"
import dayjs from "dayjs"
import storage from "../utils/http/storage"

var backApiUri = configuration.back_api_uri

export const createLeague = (name: string, startDate: any, endDate: any) => {
  var league = {
    name,
    startDate,
    endDate,
  }
  return Http.setMethod(httpMethods.post)
    .setEndpoint(`${backApiUri}/league`)
    .setToken(storage.getToken())
    .setPayload(league)
    .request<League>()
}

export const joinLeague = (leagueId: string, teamId: string) => {
  return makeRequest(`${backApiUri}/league/${leagueId}/join`, "POST", {
    TeamId: teamId,
  })
}

export const leaveLeague = (leagueId: string, teamId: string) => {
  return makeRequest(`${backApiUri}/league/${leagueId}/leave`, "POST", {
    TeamId: teamId,
  })
}

export const joinLeagueByCode = (code: string, teamId: string) => {
  return makeRequest(`${backApiUri}/league/join-by-code?code=${code}`, "POST", {
    TeamId: teamId,
  })
}

export const graphql = (query: object) => {
  return makeRequest(`${backApiUri}/graphql/`, "POST", query)
}

export const getLeague = (leagueId: string) => {
  return Http.setMethod(httpMethods.get)
    .setEndpoint(`${backApiUri}/league/${leagueId}`)
    .setToken(storage.getToken())
    .request<League>()
}
