import { Button, Grid } from "@mui/material"
import { Link } from "react-router-dom"

export const NeedToLogin = (props: any) => {
  return (
    <>
      <center>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sx={{ color: "text.primary" }}>
            Sign in to see this page
          </Grid>
          <Grid item xs={12}>
            <Link
              to={`/login?returnUrl=${props.returnUrl}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                style={{ width: "150px" }}
                variant="contained"
                color="success"
              >
                Sign in
              </Button>
            </Link>
          </Grid>
        </Grid>
      </center>
    </>
  )
}
