import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useState } from "react"
import dayjs from "dayjs"
import { SearchActivity } from "./searchActivity"
import { Camera } from "./camera"
import { toast } from "react-toastify"
import { Check } from "../../utils/ToastContent"
import { addActivityHistory } from "../../api/activityApi"
import axios from "axios"
import { configuration } from "../../utils/oidc/configurations"
import Resizer from "react-image-file-resizer"
import "./style.css"

export interface AddActivityDialogProps {
  open: boolean
  onClose: () => void
}

interface ActivityOptionType {
  inputValue?: string
  name: string
  id: string
}

export const AddActivityDialog = (props: AddActivityDialogProps) => {
  const { onClose, open } = props
  const [activity, setActivity] = useState<ActivityOptionType>()
  const [duration, setDuration] = useState(0)
  const [comment, setComment] = useState("")
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs(new Date()))
  const [selectedFile, setSelectedFile] = useState<any>()
  var backApiUri = configuration.back_api_uri

  const clearForm = () => {
    setDuration(0)
    setComment("")
    setDate(dayjs(new Date()))
    setSelectedFile(null)
  }

  const handleClose = () => {
    clearForm()
    onClose()
  }

  const resizeFile = (file: any) =>
    new Promise<any>((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri)
        },
        "file",
      )
    })

  const handleSubmit = async (event: any) => {
    if (selectedFile) {
      const resized = await resizeFile(selectedFile)
      const formData = new FormData()
      formData.append("formFile", resized)
      formData.append("fileName", "fileName")
      axios.post(`${backApiUri}/File`, formData).then((result) => {
        const fileName = result.data
        addActivityHistory(
          activity?.id,
          duration,
          date,
          fileName,
          comment,
        ).then(() => {
          toast.success(<Check text={`Activity added`} />)
          clearForm()
          onClose()
        })
      })
    } else {
      addActivityHistory(activity?.id, duration, date, "", comment).then(() => {
        toast.success(<Check text={`Activity added`} />)
        clearForm()
        onClose()
      })
    }
  }

  const addTime = (time: number) => {
    setDuration(+duration + +time)
  }

  const onDurationChange = (event: any) => {
    setDuration(event.target.value)
  }

  const onCommentChange = (event: any) => {
    setComment(event.target.value)
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <DialogTitle>
        <Grid
          item
          container
          justifyContent={"center"}
          sx={{ fontWeight: "700" }}
        >
          Add Activity
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box p={1}>
          <Grid sx={{ width: "100%" }}>
            <SearchActivity setActivity={setActivity} />
          </Grid>
          <Grid>
            <Box pt={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date"
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                    format="DD.MM.YYYY"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid>
            <Box pt={1}>
              <TextField
                sx={{ p: 0, width: "100%" }}
                id="duration"
                label="Duration"
                variant="outlined"
                value={duration}
                onChange={onDurationChange}
              />
            </Box>
            <Box>
              <Box display="flex" justifyContent="right" width={1} pt={1}>
                <Box className="sub-cell">
                  <Chip
                    sx={{ backgroundColor: "#233257" }}
                    size="small"
                    label="+15min"
                    onClick={() => addTime(15)}
                  />
                </Box>
                <Box className="sub-cell">
                  <Chip
                    sx={{ backgroundColor: "#233257" }}
                    size="small"
                    label="+30min"
                    onClick={() => addTime(30)}
                  />
                </Box>
                <Box className="sub-cell">
                  <Chip
                    sx={{ backgroundColor: "#233257" }}
                    size="small"
                    label="+1hr"
                    onClick={() => addTime(60)}
                  />
                </Box>
                <Box className="sub-cell">
                  <Chip
                    sx={{ backgroundColor: "#233257" }}
                    size="small"
                    label="+2hrs"
                    onClick={() => addTime(120)}
                  />
                </Box>
              </Box>
            </Box>
            <Box pt={1}>
              <TextField
                sx={{ p: 0, width: "100%" }}
                id="comment"
                label="Comments"
                variant="outlined"
                value={comment}
                onChange={onCommentChange}
                multiline
                rows={3}
                placeholder="How did it go?"
              />
            </Box>
          </Grid>
          <Grid item container justifyContent={"center"}>
            <Grid sx={{ m: 2 }}>
              <Camera
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"}>
          <Stack spacing={1} direction={"row"}>
            <Button
              type="submit"
              variant="outlined"
              color="success"
              sx={{
                fontWeight: "500",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              onClick={handleSubmit}
              sx={{
                fontWeight: "500",
              }}
            >
              Add activity
            </Button>
          </Stack>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
