import Http, { httpMethods } from "../utils/http/apiHelper"
import { makeRequest } from "../utils/http/http"
import storage from "../utils/http/storage"
import { configuration } from "../utils/oidc/configurations"
import { User } from "../utils/types/user"

var backApiUri = configuration.back_api_uri

export const registerUser = (user: User) => {
  return makeRequest(`${backApiUri}/user`, "POST", user)
}

export const getUserInfo = (
  userId: string,
  teamId: string,
  leagueId: string,
) => {
  var query = {
    query: `{
      users(where: { id: { eq: ${userId} } }) {
        id
        email
        teams(where: { id: { eq: ${teamId} } }) {
          id
          name
          roles {
            name
          }
          leagues(where: { id: { eq: ${leagueId} } }) {
            id
            name
            roles {
              name
            }
          }
        }
      }
    }
  `,
  }
  return Http.setMethod(httpMethods.post)
    .setEndpoint(`${backApiUri}/graphql/`)
    .setPayload(query)
    .request()
}

export const getUsersSummarized = (leagueId: string) => {
  return Http.setMethod(httpMethods.get)
    .setEndpoint(`${backApiUri}/league/${leagueId}/users/summarized`)
    .setToken(storage.getToken())
    .request<any>()
}
