const storage = {
  get: (key: string) => window.localStorage.getItem(key),
  set: (key: string, val: string) => window.localStorage.setItem(key, val),
  remove: (key: string) => window.localStorage.removeItem(key),
}

const sessionStorage = {
  get: (key: string) => window.sessionStorage.getItem(key),
  set: (key: string, val: string) => window.sessionStorage.setItem(key, val),
  remove: (key: string) => window.sessionStorage.removeItem(key),
}

const setToken = (token: string) => {
  sessionStorage.set("@oidc.default", token)
}

// const setFilter = (key:string,userName :string, filters: string) => {
// 	storage.set(@filter.${key}.${userName}, filters);
// };

// const removeFilter = (key:string,userName :string, filters: string) => {
// 	storage.remove(@filter.${key}.${userName});
// };

const getLoggedUserName = (): string => {
  const value = storage.get("@LoggedUserName")
  return value == null || value === undefined ? "" : value
}

//const getSelectedFilter = (key:string,userName :string): string => storage.get(@filter.${key}.${userName}) || '';
const getSelectedFilter = (key: string, userName: string): string => {
  const filterKey = `@filter.${key}.${userName}`
  const filter = storage.get(filterKey)
  return filter == null || filter === undefined ? "" : filter
}

// const getToken = (): string => storage.get('@Token') || '';

const getToken = (): string => {
  const storedData = sessionStorage.get("oidc.default")
  if (storedData) {
    const parsedData = JSON.parse(storedData)
    const accessToken = parsedData.tokens.accessToken
    return accessToken
  }
  return ""
}

const getSelectedLanguage = (): string =>
  storage.get("@SelectedLanguage") || "en"

export default {
  setToken,
  getToken,
  getSelectedLanguage,
  getSelectedFilter,
  getLoggedUserName,
}
