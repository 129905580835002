import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export interface UserState {
  id: string
}

const initialState: UserState = {
  id: "",
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
  },
})

export const { setUserId } = userSlice.actions

export const selectId = (state: RootState) => state.user.id

export default userSlice.reducer
