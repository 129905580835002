import { useOidcUser } from "@axa-fr/react-oidc"
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import { graphql } from "../../api/teamApi"
import { useSelector } from "react-redux"
import { RootState } from "../../app/store"
import {
  selectCurrent,
  selectLeagueId,
} from "../../features/league/leagueSlice"
import ReactTimeAgo from "react-time-ago"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import FavoriteIcon from "@mui/icons-material/Favorite"
import ShareIcon from "@mui/icons-material/Share"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { ActivityMenu } from "./activityMenu/activityMenu"

export const Leaderboard = () => {
  const { oidcUser, oidcUserLoadingState } = useOidcUser()
  const [activityHistories, setActivityHistories] = useState<any>()
  const leagueId = useSelector((state: RootState) => selectLeagueId(state))
  const league = useSelector((state: RootState) => selectCurrent(state))
  const authorityUrl = import.meta.env.VITE_AUTHORITY
  const callbackUrlEncoded = window.location.href
    .replace(":", "%253A")
    .replace("/", "%252F")

  const fetchData = () => {
    graphql({
      query: `{
            activityHistories 
            (order: [{date: DESC}]
              where: {
                user: {
                  teams: {
                    some: {
                      leagues: {
                        some: {
                          id: {
                            eq: "${leagueId}"
                            }
                        }
                      }
                    }
                  }
                }
                date: {
                  gte : "${league?.startDate}" 
                  lte : "${league?.endDate}" 
                 }
              }) {
            date,
            duration,
            imageReference,
            comment,
            user {
              id
              fullName,
              pictureUrl,
              teams {
                name,
              }
            },
            activity {
            name,
          
          }
            } 
          }`,
    })
      .then((data: any) => {
        setActivityHistories(data.data.activityHistories)
      })
      .catch((error: any) => {})
  }
  const renderCard = (item: any) => {
    return (
      <Card sx={{ minWidth: "95vw" }}>
        <CardHeader
          sx={{ padding: "5px" }}
          avatar={
            <Tooltip title={item?.fullName}>
              {item.user.pictureUrl ? (
                <img
                  src={item?.user.pictureUrl}
                  className="header-profile-picture"
                  alt={"UserImage"}
                />
              ) : (
                <img
                  src={`https://eu.ui-avatars.com/api/?background=random&name=${item?.user?.fullName}&rounded=true&size=100&bolde=true`}
                  className="header-profile-picture"
                  alt={"UserImage"}
                />
              )}
            </Tooltip>
          }
          // action={item?.user.id === oidcUser.sub && <ActivityMenu />}
          title={<Box sx={{ fontWeight: "800" }}>{item?.user?.fullName}</Box>}
          subheader={
            <>
              <Stack>
                <Box
                  sx={{ fontSize: "x-small", color: "rgb(255 255 255 / 60%)" }}
                >
                  Member of {item.user.teams[0].name}
                </Box>
                <Box
                  sx={{
                    fontSize: "x-small",
                    marginTop: "-3px",
                    color: "rgb(255 255 255 / 60%)",
                  }}
                >
                  <ReactTimeAgo date={item.date} locale="en-US" />
                </Box>
              </Stack>
            </>
          }
        />
        <CardActionArea>
          {item.imageReference && (
            <CardMedia
              component="img"
              height="400"
              // image={item?.user.pictureUrl}
              image={`../uploads/${item.imageReference}`}
              alt="skates"
            />
          )}
          <CardContent sx={{ borderBottom: "1px solid grey" }}>
            <Box>
              <Stack direction={"row"}>
                <Box sx={{ fontSize: "large", fontWeight: "800" }}>
                  {item.activity.name}{" "}
                </Box>
                <Box
                  sx={{
                    fontSize: "small",
                    fontWeight: "800",
                    marginTop: "6px",
                    marginLeft: "8px",
                    color: "#ffffffb3",
                  }}
                >
                  <Stack direction="row" spacing={0}>
                    <Box>
                      <AccessTimeIcon sx={{ fontSize: "medium" }} />
                    </Box>
                    <Box>{item.duration} mins</Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
            <Typography>{item.comment}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
        </CardActions>
      </Card>
    )
  }

  useEffect(() => {
    oidcUser && fetchData()
  }, [leagueId, oidcUser])

  return (
    <Box>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        p={1}
        sx={{ marginTop: "5px", width: "100vw", marginBottom: "150px" }}
      >
        <Stack spacing={1} sx={{ overflow: "scroll" }}>
          {activityHistories && activityHistories.length !== 0 ? (
            activityHistories.map((item: any) => {
              return renderCard(item)
            })
          ) : (
            <Box sx={{ color: "white" }}>No activites registered</Box>
          )}
        </Stack>
      </Grid>
    </Box>
  )
}
